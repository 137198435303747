import { memo, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'

import { colors } from 'libs/theme'
import { heights } from 'libs/items'
import { doWithTagManager } from 'libs/local'

import { Grid, Text } from 'components/elements'
import { Button } from 'components/elements/Buttons'
import Icons from 'components/elements/Icons'

const Navigation = memo(({ current = 'home' }) => {
  const router = useRouter()
  const { breakpoints, safeAreaInsets } = useSelector(state => state.default)
  const [selected, setselected] = useState(current)
  const [isInit, setisInit] = useState(false)
  const [isNoNavigator, setisNoNavigator] = useState(false)

  const isNoNavigatorPages = ['/account']
  const items = [
    { key: 'home', name: '홈', page: '/', same: '/', gtm: 'gtm_click_nav_home' },
    { key: 'prescribe', name: '비대면 진료', page: '/prescribe', indexOf: '/prescribe', gtm: 'gtm_click_nav_prescribe' },
    { key: 'store', name: '쇼핑', page: '/store/project', indexOf: '/store', gtm: 'gtm_click_nav_store' },
    // { key: 'treatment', name: '남성시술', page: '/treatment', indexOf: '/treatment', gtm: 'gtm_click_nav_treatment' },
    { key: 'community', name: '썰즈톡', page: '/community', indexOf: '/community', gtm: 'gtm_click_nav_community' },
    { key: 'sir', name: 'sir', page: '/sir', indexOf: '/sir', gtm: 'gtm_click_nav_mypage' }
    // { key: 'supplement', name: '영양제 구매', page: '/supplement', indexOf: '/supplement' }
  ]

  useEffect(() => {
    const f = isNoNavigatorPages.find(p => router.pathname.indexOf(p) > -1)
    if (f) setisNoNavigator(true)
    else {
      setisNoNavigator(false)
      const finded = items.find(item => (item.same && item.same === router.pathname) || (item.indexOf && router.pathname.indexOf(item.indexOf) > -1))
      if (finded) setselected(finded.key)
      else setselected()
    }
  }, [router.asPath])

  useEffect(() => {
    setisInit(true)
  }, [])

  if (!isInit || isNoNavigator) return null

  return (
    <Grid columns={`repeat(${items.length}, 1fr)`} position='fixed' color={colors.white} width='100%' maxWidth={`${heights.maxWidth}px`}
          padding={`4px 0 ${8 + (breakpoints?.isIos && !!safeAreaInsets ? 8 : 0)}px 0`}
          style={{ bottom: '0px', zIndex: 111, boxShadow: '0 -8px 10px 0 rgba(5, 67, 94, 0.05)' }}>
      {
        items.map(item => (
          <Button key={item.key} variant='text' onClick={() => doWithTagManager(item.gtm, () => router.push(item.page))}>
            <Grid justify='center' align='center' padding='4px' gap='4px' height='40px'>
              <Icons icon={item.key} width='24px' height='24px' color={selected === item.key ? colors.primary.light : colors.disabled} />
              <Text variant='label1' bold color={selected === item.key ? colors.primary.light : colors.disabled}>{item.name}</Text>
            </Grid>
          </Button>
        ))
      }
    </Grid>
  )
}, () => {
  return true
})

export default Navigation
