import React, { memo, useState, useRef, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'

import { setAlert, setBanner, setIsOpenNotification } from 'store/default'
import { colors, spaces } from 'libs/theme'
import { heights } from 'libs/items'

import { Grid, Text } from 'components/elements'
import Icons, { Logo } from 'components/elements/Icons'
import { Button } from 'components/elements/Buttons'
import { doWithTagManager, getLocalItem, setLocalItem } from 'libs/local'

let lastY
const key = 'stop-mobile-app-download-time'
const Header = memo(({
  isNew, isBanner, setisOpenMenu, defaultBackgroundColor = colors.white
}) => {
  const dispatch = useDispatch()
  const ref = useRef()
  const router = useRouter()
  const { breakpoints, safeAreaInsets, banner, isOpenNotification, user } = useSelector(state => state.default)

  const [isInit, setisInit] = useState(false)
  const [height, setheight] = useState('150px')
  const [isOnTop, setisOnTop] = useState(true)

  const scroll = e => {
    const rect = e.target.body.getBoundingClientRect()
    if (rect.top > -10) setisOnTop(true)
    else setisOnTop(false)
    if (!breakpoints?.isAndroid && !breakpoints?.isIos && !breakpoints?.sm) {
      if (lastY <= rect.top || rect.top >= -10) setheight('150px')
      else setheight('0px')
      lastY = rect.top
    }
  }

  const checkMobileAppDownload = () => {
    const time = getLocalItem(key)
    if (!breakpoints.md && !breakpoints.isIos && !breakpoints.isAndroid && (!time || (!!time && time < new Date().getTime()))) {
      dispatch(setBanner(true))
    }
  }

  const stopCheckMobileAppDownload = () => {
    // 닫기 클릭 => 48시간 후 다시 오픈
    doWithTagManager('gtm_click_home_topbanner')
    setLocalItem(key, new Date().getTime() + 1000 * 60 * 60 * 48)
    dispatch(setBanner(false))
  }

  const goHome = () => {
    router.push('/')
  }

  const openNotification = () => {
    if (!user) dispatch(setAlert({ body: '로그인이 필요한 기능입니다.', onClick: () => router.push('/account') }))
    else dispatch(setIsOpenNotification(!isOpenNotification))
  }

  useEffect(() => {
    if (breakpoints) {
      window.addEventListener('scroll', scroll, false)
      setisInit(true)
    }
    return () => {
      window.removeEventListener('scroll', scroll, false)
    }
  }, [breakpoints])

  useEffect(() => {
    if (breakpoints?.os) checkMobileAppDownload()
  }, [breakpoints])

  if (!isInit) return null

  return (
    <header style={{
      maxHeight: height,
      overflow: 'hidden',
      maxWidth: heights.maxWidth,
      backgroundColor: isOnTop ? defaultBackgroundColor : colors.white,
      boxShadow: isOnTop ? 'none' : '0px 8px 10px rgba(5, 67, 94, 0.05)',
      paddingTop: breakpoints?.isIos && safeAreaInsets ? `${safeAreaInsets.top}px` : '',
      transition: 'max-height 0.2s ease-in-out'
    }}>
      {
        isBanner && banner && (
          <Grid columns='24px 1fr 24px' color={colors.primary.dark} justify='center' padding={`${spaces.component.s4.px} ${spaces.default.px}`} align='center'>
            <div />
            <Button variant='text' padding='0' onClick={() => router.push('http://bit.ly/3xYIWCl')}>
              <Text color={colors.white}>앱으로 더 편하게 사용하기</Text>
            </Button>
            <Button padding='0' variant='text' onClick={stopCheckMobileAppDownload}>
              <Icons icon='close' width='24' height='24' color={colors.white} />
            </Button>
          </Grid>
        )
      }
      <div className='inner'>
        <h1>
          <a onClick={goHome}>
            <Logo width='52' color={colors.primary.dark} />
          </a>
        </h1>
        <div className='utill' ref={ref}>
          <Button onClick={openNotification}>
            <Grid position='relative' align='center'>
              {
                isNew && <Grid position='absolute' width='8px' height='8px' borderRadius='100%' color={colors.error} style={{ right: 3, top: 3 }} />
              }
              <Icons icon='alarm' width='24' height='24' color={colors.active} />
            </Grid>
          </Button>
          <Button variant='text' radius='0' onClick={() => router.push('/store/cart')}>
            <Icons icon='cart' width='24px' height='24px' color={colors.grey.g800} />
          </Button>
        </div>
      </div>
    </header>
  )
}, (p, n) => {
  if (p.isNew !== n.isNew) return false
  if (p.isBanner !== n.isBanner) return false
  return true
})

export default Header
