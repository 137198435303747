import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'

import { heights } from 'libs/items'
import { getLocalItem } from 'libs/local'
import { setAlert, setIsOpenNotification } from 'store/default'

import Header from 'components/commons/header/Header'
import Footer from 'components/commons/Footer'
import Navigation from 'components/commons/Navigation'
import Notification from 'components/commons/Notification'

const key = 'stop-mobile-app-download-time'
const GNB = ({
  isBanner = false, isHeader = true, headerBackgroundColor,
  isFooter = false, isNavigation = true,
  isTopOnChangePage = true,
  children
}) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const { user, breakpoints, banner, safeAreaInsets, isOpenNotification } = useSelector(state => state.default)
  const [isInit, setisInit] = useState(false)
  const [notifications, setnotifications] = useState()
  const [isNew, setisNew] = useState(false)
  const [isBannerChecked, setisBannerChecked] = useState(true)

  const getNotification = async () => {
    const { success, data } = await window.api.request('get', 'notifications', { page: 1, page_size: 99 })
    if (success) {
      if (data.results.findIndex(d => !d.is_read) > -1) setisNew(true)
      else setisNew(false)
      setnotifications(data.results)
    }
  }

  const setStyle = () => {
    let paddingBottom
    let h = isHeader ? heights.header : 0
    if (breakpoints?.isIos && !!safeAreaInsets) {
      h += safeAreaInsets.top
      paddingBottom = 90
    }
    return { paddingBottom, paddingTop: `${h > 0 ? h - 1 : 0}px` }
    // return { height: `calc(100vh - ${h + (isNavigation ? heights.footer : 0)}px)`, paddingTop: `${h > 0 ? h - 1 : 0}px`, paddingBottom }
  }

  const checkMobileAppDownload = () => {
    const time = getLocalItem(key)
    if (!isBanner && !breakpoints.md && !breakpoints.isIos && !breakpoints.isAndroid && (!time || (!!time && time < new Date().getTime()))) {
      setisBannerChecked(false)
    }
  }

  useEffect(() => {
    if (isTopOnChangePage) {
      window.scrollTo(0, 0)
      // setTimeout(() => window.scrollTo(0, 0), 1000)
    }
    dispatch(setIsOpenNotification(false))
  }, [router.asPath])

  useEffect(() => {
    if (isOpenNotification) {
      window.scrollTo(0, 0)
      window.document.body.style.overflow = 'hidden'
      if (!user) {
        dispatch(setAlert(
          {
            body: '로그인이 필요한 기능입니다.',
            onClick: () => router.replace(`/account?page=${encodeURIComponent(window.location.pathname + window.location.search)}`),
            onClose: () => router.replace(`/account?page=${encodeURIComponent(window.location.pathname + window.location.search)}`)
          }
        ))
      }
    } else {
      window.document.body.style.overflow = ''
    }
  }, [isOpenNotification])

  useEffect(() => {
    if (user) getNotification()
  }, [user])

  useEffect(() => {
    if (breakpoints?.os) checkMobileAppDownload()
  }, [breakpoints?.os, router.asPath])

  useEffect(() => {
    setisInit(true)
  }, [])

  return (
    <>
      <div className='layout-body'>
        <div style={setStyle()}>
          {isHeader && <Header isNew={isNew} isBanner={isBannerChecked} defaultBackgroundColor={headerBackgroundColor} />}
          {isInit && children}
          {
            isFooter && (!breakpoints.isIos && !breakpoints.isAndroid)
              ? <Footer />
              : <div style={{ height: 72 }} />
          }
        </div>
        {isNavigation && <Navigation />}
        <Notification notifications={notifications} refresh={getNotification} isOpen={isOpenNotification} close={() => dispatch(setIsOpenNotification(false))} />
      </div>
    </>
  )
}

export default GNB
