import React, { memo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'

import Terms from 'components/commons/Terms'

const Footer = memo(() => {
  const { breakpoints } = useSelector(state => state.default)
  const router = useRouter()
  const [isTerms, setisTerms] = useState(false)

  return (
    <>
      <footer style={{ paddingBottom: 72 }}>
        <div className='ftr-inner'>
          <div className='about-support'>
            <div className='about'>
              <h3>About</h3>
              <ul>
                <li><a onClick={() => setisTerms(true)}>이용약관</a></li>
                <li><a onClick={() => window.open('https://app.catchsecu.com/document/P/48e0e70fc209e78')}>개인정보 처리방침</a></li>
                <li><a onClick={() => window.open('https://app.catchsecu.com/document/P/31edf0e1425397e')}>개인정보 처리방침(파트너)</a></li>
              </ul>
            </div>
            <div className='support' style={{ width: 160 }}>
              <h3>Support</h3>
              <ul>
                <li><a onClick={() => router.push('/company/notice')}>공지사항</a></li>
                <li><a onClick={() => router.push('/company/faq')}>FAQ</a></li>
                <li><a onClick={() => window.open('https://sirs.channel.io/')}>고객센터 &amp; 파트너 문의</a></li>
              </ul>
            </div>
          </div>
          <div className='info'>
            <em>주식회사 트러스트랩스 (Trust Labs Inc.)</em>
            <address>서울시 서초구 서초대로 398 BNK 디지털타워 5층</address>
            <ul>
              <li>사업자번호 261-88-02197</li>
              <li>통신판매업 제2022-서울서초-01324호</li>
              <li>대표자명 김태우</li>
            </ul>
            <ul>
              <li>고객센터 1668-3861 (9:30 - 18:30)</li>
              <li>대표메일 info@sirs.kr</li>
              <li><a onClick={() => window.open('https://sirs.channel.io/')}>고객센터 & 파트너(병원/약국) 문의</a></li>
            </ul>
          </div>
        </div>
      </footer>
      <Terms isOpen={isTerms} isFull={!breakpoints.md} setIsOpen={setisTerms} />
    </>
  )
}, () => {
  return true
})

export default Footer
