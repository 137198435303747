import { memo } from 'react'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'

import { setAlert } from 'store/default'
import { format } from 'libs/time'
import { colors } from 'libs/theme'

import { Grid, Text } from 'components/elements'
import Icons from 'components/elements/Icons'
import { Button } from 'components/elements/Buttons'

const Notification = memo(({ notifications, refresh, isOpen, close }) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const { breakpoints, safeAreaInsets } = useSelector(state => state.default)
  const select = notification => {
    window.api.request('post', 'notifications', { notifications: [notification.id] }, { path: 'read', isErrorCallback: true })
    refresh()
    router.push(notification.redirect_to)
  }

  const readAll = () => {
    dispatch(setAlert({
      type: 'confirm',
      body: '모든 알림을 읽음 상태로 변경하시겠습니까?',
      buttonText: '읽음 상태로 변경',
      onClick: async () => {
        const ids = notifications.filter(n => !n.is_read).map(n => n.id)
        await window.api.request('post', 'notifications', { notifications: ids }, { path: 'read', isErrorCallback: true })
        refresh()
      }
    }))
  }

  return (
    <Grid className={`notification${isOpen ? ' open' : ''}`} style={{ paddingTop: breakpoints?.isIos && !!safeAreaInsets ? safeAreaInsets.top : 0 }}>
      <Grid padding='16px' gap='20px' overflow='auto' align='flex-start'>
        <Grid columns='75px 1fr 75px' justify='flex-start'>
          <Button variant='text' width='24px' onClick={close}>
            <Icons icon='close' width='24' height='24' color='#666666' />
          </Button>
          <Grid justify='center' width='100%' align='center'>
            <Text bold size='18px' align='center'>알림</Text>
          </Grid>
          {
            !!notifications && !!notifications.find(n => !n.is_read) ? (
              <Button variant='text' width='100%' onClick={readAll}>
                <Text color={colors.primary.light} bold>모두 읽음</Text>
              </Button>
            ) : <div />
          }
        </Grid>
        <Grid columns='1fr'>
          {
            !!notifications && (
              notifications.length === 0 ? (
                <Grid height='200px' justify='center' align='center' style={{ borderTop: '1px solid #EBEBEB' }}>
                  <Text size='14px'>알림이 없습니다</Text>
                </Grid>
              ) : (
                notifications.map(n => (
                  <Button variant='text' key={n.id} onClick={n.redirect_to ? () => select(n) : undefined}>
                    <Grid width='100%' justify='flex-start' position='relative' padding='15px 12px' gap='8px' style={{ borderTop: '1px solid #EBEBEB' }}>
                      <Text size='10px' color={colors.primary.light}>{format(n.created_at, 'M월 D일')}</Text>
                      <Text align='left'>{n.message}</Text>
                      {
                        !n.is_read && <Grid isInline position='absolute' color='#FF0000' width='10px' height='10px' borderRadius='100%' style={{ right: 12, top: 17 }} />
                      }
                    </Grid>
                  </Button>
                ))
              )
            )
          }
          <Grid style={{ borderTop: '1px solid #EBEBEB' }} />
        </Grid>
      </Grid>
    </Grid>
  )
}, (p, n) => {
  if (p.isOpen !== n.isOpen) return false
  if (p.notifications !== n.notifications) return false
  return false
})

export default Notification
